import React, { useState } from 'react'
import ReactDOM from 'react-dom'
import './index.css'

function Square(props) {
    return (
        <button
            className={`square ${props.color_class}`}
            onClick={props.onClick}
        >
            {props.value}
        </button>
    )
}

const Board = (props) => {
    
    const renderSquare = (i, j, value, windex) => {
        return (
            <Square key={`square-${i}-${j}`}
                value={value} 
                color_class = {windex(i, j) ? "winner-thing" : "white-thing"}
                onClick={() => props.onClick(i, j)}
            />
        )
    }

    return (
        <div>
            {props.squares.map((line, row) => (
                <div className="board-row" key={`row-${row}`}>
                    {line.map((entry, col) => 
                        renderSquare(row,col,entry,props.windex))}
                </div>
            ))}
        </div>
    )
}

const Game = (props) => {

    const [history, setHistory] = useState([{
        squares: Array.from(Array(props.num_rows), _ => Array(props.num_cols).fill(null)),
        row: undefined,
        col: undefined,
    }])
    const [stepNumber, setStepNumber] = useState(0)
    const [xIsNext, setXIsNext] = useState(true)
    const [moves_ascending, setMovesAscending] = useState(true)

    const resetGame = () => {
        setHistory([{
            squares: Array.from(Array(props.num_rows), _ => Array(props.num_cols).fill(null)),
            row: undefined,
            col: undefined,
        }])
        setStepNumber(0)
        setXIsNext(true)
        setMovesAscending(true)
    }

    const handleClick = (i, j) => {
        const modern_history = history.slice(0, stepNumber + 1)
        const current = modern_history[modern_history.length - 1] 
        const squares = deepcopy(current.squares)
        if (calculateWinner(squares) || squares[i][j]) {
            return
        }
        const current_row = i
        const current_col = j
        squares[i][j] = xIsNext ? 'X' : 'O'
        setHistory(modern_history.concat([{
            squares: squares,
            row: current_row,
            col: current_col, 
        }]))
        setStepNumber(modern_history.length)
        setXIsNext(!xIsNext)
    }

    const toggle_move_list = () => {
        setMovesAscending(!moves_ascending)
    }

    const jumpTo = step => {
        setStepNumber(step)
        setXIsNext(step % 2 === 0)
    }

    const current = history[stepNumber]
    const winner_info = calculateWinner(current.squares)
    let status
    let windex = (_i, _j) => false
    if (winner_info) {
        status = 'Winner: ' + winner_info.value
        windex = winner_info.windex 
    } else if (is_full(current.squares)) {
        status = "game tied, no winner today"
    } else {
        status = 'Next Player: ' + (xIsNext ? 'X' : 'O')
    }

    const moves = history.map((step, move) => {
        const desc = move ?
            `Go to move (${step.row+1},${step.col+1})` :
            'Go to game start'
        const bold_desc = (move === stepNumber) ?
            <b>{desc}</b> :
            desc
        return (
            <b>
                <li key={move}>
                    <button onClick={() => jumpTo(move)}>{bold_desc}</button>
                </li>
            </b>
        )
    })
    const button_list = moves_ascending ? moves : moves.reverse()
    return (
        <div className="game">
            <div className="game-board">
                <Board
                    squares={current.squares}
                    onClick={(i, j) => handleClick(i, j)}
                    windex={windex}
                />
            </div>
            <div className="game-info">
                <div>{status}</div>
                <button onClick={() => toggle_move_list()}>{
                    moves_ascending ? '▼' : '▲'}</button>
                <button onClick={() => resetGame()}>Reset</button>
                <ol reversed={!moves_ascending}>{button_list}</ol>
            </div>
        </div>
    )
}

// ========================================

const root = ReactDOM.createRoot(document.getElementById("root"))
root.render(<Game num_rows={4}
                  num_cols={4}
            />)

// ReactDOM.render(
//     <Game />,
//     document.getElementById('root')
// )

function deepcopy(L) {
    return L.map(item => Array.isArray(item) ? deepcopy(item) : item)
}

function is_full(grid) {
    return grid.every(item => Array.isArray(item) ? is_full(item) : item != null)
}

function transpose(grid) {
    return grid.reduce((prev, curr) => prev
        .map((sub_array, i) => (sub_array.concat(curr[i]))),
    Array.from(Array(grid[0].length), _ => []))
}

function find_winning_row_index(grid) {
    return grid.findIndex(row => row[0] && row
        .every(value => value === row[0]))
}

function calculateWinner(grid) {

    const winning_row_index = find_winning_row_index(grid)
    if (winning_row_index > -1) {
        return {
            value: grid[winning_row_index][0],
            windex: (i, _j) => i === winning_row_index
        }
    }

    const winning_col_index = find_winning_row_index(transpose(grid))
    if (winning_col_index > -1) {
        return {
            value: grid[0][winning_col_index],
            windex: (_i, j) => j === winning_col_index
        }
    }

    const major_diagonal = grid.map((row, i) => row[i])
    if (find_winning_row_index([major_diagonal]) > -1) {
        return {
            value: major_diagonal[0],
            windex: (i, j) => i === j
        }
    }

    const minor_diagonal = grid.map((row, i) => row[row.length - i - 1])
    if (find_winning_row_index([minor_diagonal]) > -1) {
        return {
            value: minor_diagonal[0],
            windex: (i, j) => i + j === grid.length - 1
        }
    }

    return null
}
